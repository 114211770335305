<template>
  <v-form v-model="valid" ref="form" @submit.prevent="" lazy-validation>
    <CopyToInvite />
  </v-form>
</template>

<script>
import CopyToInvite from "@/components/copy_to/CopyToInvite";
export default {
  name: "CopyToInviteForm",
  components: { CopyToInvite },
  data() {
    return {
      valid: false
    };
  }
};
</script>

<style scoped></style>
