<template>
  <Page title="Report Recipient - Invite">
    <CopyToInviteForm />
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import CopyToInviteForm from "@/components/copy_to/CopyToInviteForm";

export default {
  name: "Invite",
  components: { Page, CopyToInviteForm }
};
</script>
